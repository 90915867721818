
































import { Component, Watch, Vue } from 'vue-property-decorator';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import { CHART_CLICK_EVENT, TASQ_OFF_TARGET_TYPE } from '@/lib/constants';
import defermentLabelingModule from '@/store/modules/defermentLabelingModule';
import tasqsListModule from '@/store/modules/tasqsListModule';

@Component({
  components: {
    LineChart: () => import('@/lib/charts/lineChart'),
  },
})
export default class TasqProductionDataChart extends Vue {
  highlightChart = false;

  get productionData() {
    return tasqProductionDataChartModule.productionData;
  }

  get defermentLabelingData() {
    return defermentLabelingModule.chartLabelingData;
  }

  get isChartLoaded() {
    return this.productionData?.loaded;
  }

  created() {
    this.$eventBus.$on('HIGHLIGHT_CHART', (val) => {
      this.highlightChart = val;
    })
  }

  chartData: any = {
    datasets: [
      {
        label: '',
        data: [],
        borderColor: '#666A96',
        backgroundColor: 'rgba(102,106,150,0.09)',
        tension: 0,
        borderWidth: 2,
        fill: true,
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverRadius: 3,
        pointBorderColor: '#666A96',
        pointHoverBackgroundColor: '#FFFFFF',
        pointHoverBorderColor: '#FFFFFF',
        pointBackgroundColor: '#666A96',
        yAxisID: 'y-axis-1',
        spanGaps: true,
      },
      {
        label: '',
        data: [],
        borderColor: '#0076FF',
        backgroundColor: 'rgba(0, 118, 255, 0.095)',
        tension: 0,
        borderWidth: 2,
        fill: false,
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverRadius: 3,
        pointBorderColor: '#0076FF',
        pointHoverBackgroundColor: '#FFFFFF',
        pointHoverBorderColor: '#FFFFFF',
        pointBackgroundColor: '#0076FF',
        yAxisID: 'y-axis-1',
        spanGaps: true,
      },
      {
        label: '',
        data: [],
        borderColor: '#2CE6C2',
        backgroundColor: 'rgba(44,230,194,0.09)',
        tension: 0,
        borderWidth: 2,
        fill: false,
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverRadius: 3,
        pointBorderColor: '#2CE6C2',
        pointHoverBackgroundColor: '#FFFFFF',
        pointHoverBorderColor: '#FFFFFF',
        pointBackgroundColor: '#2CE6C2',
        yAxisID: 'y-axis-0',
        spanGaps: true,
      },
      // {
      //   label: '',
      //   data: [],
      //   borderColor: '#6f00c1',
      //   backgroundColor: 'rgba(110,0,193,0.5)',
      //   tension: 0,
      //   borderWidth: 2,
      //   fill: false,
      //   pointRadius: 0,
      //   pointHitRadius: 5,
      //   borderDash: [10, 15],
      //   yAxisID: 'y-axis-0',
      //   spanGaps: true,
      // },
      {
        label: '',
        data: [],
        borderColor: '#2CE6C2',
        backgroundColor: 'rgba(44,230,194,0.09)',
        tension: 0,
        borderWidth: 2,
        fill: false,
        borderDash: [10, 15],
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: '#ffffff',
        pointHoverBackgroundColor: '#FFFFFF',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        unit: '',
      },
      {
        label: '',
        data: [],
        borderColor: 'transparent',
        tension: 0,
        borderWidth: 2,
        fill: false,
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: 'transparent',
        pointHoverBackgroundColor: 'transparent',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        unit: '',
      },
      {
        label: '',
        data: [],
        borderColor: 'transparent',
        backgroundColor: 'rgba(44,230,194,0.25)',
        tension: 0,
        borderWidth: 2,
        fill: '-1',
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: 'transparent',
        pointHoverBackgroundColor: 'transparent',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        unit: '',
      },
    ],
    labels: [],
  };

  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    events: ['click'],
    legend: {
      display: false,
    },
    animation: {
      duration: 500,
    },
    scales: {
      xAxes: [{
        type: 'time',
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: '#ffffff90',
          fontStyle: 'bold',
          maxTicksLimit: 8,
          minRotation: 50,
        },
        time: {
          unit: 'day',
          displayFormats: {
            day: 'MM/DD',
          },
        },
      }],
      yAxes: [
        {
          position: 'left',
          type: 'linear',
          id: 'y-axis-0',
          gridLines: {
            color: 'rgba(255, 255, 255, 0.3)',
          },
          ticks: {
            fontColor: '#ffffff90',
            maxTicksLimit: 4,
            fontStyle: 'bold',
            min: 0,
          },
        },
        {
          position: 'right',
          type: 'linear',
          id: 'y-axis-1',
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: '#ffffff90',
            maxTicksLimit: 4,
            fontStyle: 'bold',
            min: 0,
          },
        },
      ],
    },
    tooltips: {
      enabled: true,
      displayColors: false,
      backgroundColor: '#fff',
      titleFontColor: '#8C8FB0',
      bodyFontColor: '#000750',
      bodyFontStyle: 'bold',
      titleAlign: 'center',
      bodyAlign: 'center',
      xPadding: 5,
      yPadding: 5,
      callbacks: {
        title: (tooltipItems) => tooltipItems[0].xLabel.slice(5).replace(/-/g, '/'),
        label: (tooltipItems) => `${tooltipItems.yLabel.toFixed()}
          ${this.chartData.datasets[tooltipItems.datasetIndex].unit || ''}`,
      },
    },
    onClick: (evt) => {
      const chart =  (this.$refs.chart as any).$data._chart;
      const item = chart.getElementAtEvent(evt);
      if (item[0]) {
        const date = this.chartData.labels[item[0]._index];
        this.$eventBus.$emit(CHART_CLICK_EVENT, date);
      }
    },
    annotation: {
      annotations: [
      ],
    },
  }

  @Watch('isChartLoaded', {
    immediate: true,
  })
  onChartLoadedChange() {
    if (!this.productionData.data?.length) return;
    [this.chartData.datasets[0].data] = this.productionData.data;
    [, this.chartData.datasets[1].data] = this.productionData.data;
    [,, this.chartData.datasets[2].data] = this.productionData.data;
    [,,, this.chartData.datasets[4].data] = this.productionData.data;
    [,,,, this.chartData.datasets[3].data] = this.productionData.data;
    [,,,,, this.chartData.datasets[5].data] = this.productionData.data;
    this.chartData.labels = this.productionData.time;
    if (tasqsListModule.activeTasq?.engineerType === TASQ_OFF_TARGET_TYPE) {
      const mappedDataset = this.defermentLabelingData.dataset.filter(i => i.Include).map(i => i.date);
      this.chartData.datasets[2].pointBackgroundColor = this.chartData.labels.map(i => mappedDataset.includes(i) ? '#2CE6C2' : 'transparent');
      this.chartData.datasets[2].pointRadius = this.chartData.labels.map(i => mappedDataset.includes(i) ? 5 : 0);
    }
  }
}
